import { ref, computed, useContext, defineComponent, onMounted, nextTick, onBeforeUnmount, } from '@nuxtjs/composition-api';
import { merge } from 'lodash-es';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import { useProduct } from '~/modules/catalog/product/composables/useProduct';
import { useProductPrices, useReviews } from '~/modules/b2b/composables';
import { usePageStore } from '~/stores/page';
import { ProductTypeEnum } from '~/modules/catalog/product/enums/ProductTypeEnum';
import { useWishlist, useCart, useContentful, } from '~/composables';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import ProductSkeleton from '~/modules/catalog/product/components/ProductSkeleton.vue';
import { ZnLoading } from '~/modules/b2b/components';
import { ProductSpecification } from '~/modules/b2b/components/product';
import { PrescriptionFaq } from '~/modules/b2b/prescription/components';
import { LogoBar, InstallVideo, ReviewSection } from '~/modules/b2b/components/beast';
import { useBraintree } from '~/modules/b2b/checkout/composables';
import { INSTALL_VIREO_URL_MAP, QUEST_3_BUNDLE_SKU, QUEST_GOOSE_BUNDLE_SKU, SEO_META_TAGS_BY_PRODUCT, } from '~/modules/b2b/constants.client';
import { onSSR } from '@vue-storefront/core';
export default defineComponent({
    name: 'ProductPage',
    components: {
        ProductSkeleton,
        // SimpleProduct: () => import('~/modules/catalog/product/components/product-types/simple/SimpleProduct.vue'),
        // BundleProduct: () => import('~/modules/catalog/product/components/product-types/bundle/BundleProduct.vue'),
        ConfigurableProduct: () => import('~/modules/catalog/product/components/product-types/configurable/ConfigurableProduct.vue'),
        GroupedProduct: () => import('~/modules/catalog/product/components/product-types/grouped/GroupedProduct.vue'),
        InstagramFeed: () => import('~/components/InstagramFeed.vue'),
        MobileStoreBanner: () => import('~/components/MobileStoreBanner.vue'),
        RelatedProducts: () => import('~/modules/catalog/product/components/RelatedProducts.vue'),
        UpsellProducts: () => import('~/modules/catalog/product/components/UpsellProducts.vue'),
        LoadWhenVisible,
        ZnLoading,
        ProductSpecification,
        PrescriptionFaq,
        LogoBar,
        InstallVideo,
        ReviewSection,
    },
    transition: 'fade',
    setup(_, { emit }) {
        var _a;
        const loading = ref(false);
        const productFetchingLoading = ref(false);
        const { routeData } = usePageStore();
        const product = ref(null);
        const renderer = ref(ProductTypeEnum.SIMPLE_PRODUCT);
        const { addTags } = useCache();
        const { getProductDetails, loading: productLoading } = useProduct();
        const { error: nuxtError, $vsf, $eventChannel } = useContext();
        const { load: loadWishlist } = useWishlist();
        const { load: loadCart } = useCart();
        const apiState = $vsf.$magento.config.state;
        const { getClient } = useBraintree({ emit });
        const { totalReviews, MIN_REVIEWS_TO_SHOW, clearReviews, } = useReviews((_a = product.value) === null || _a === void 0 ? void 0 : _a.sku);
        const { $config } = useContext();
        const { search: seoSearch, content: seoContent } = useContentful('seoFaq');
        const getContentfulSeoData = async () => {
            await seoSearch({
                custom: {
                    type: 'seoFaq',
                    field: 'sku',
                    value: routeData.sku,
                },
            });
        };
        onSSR(async () => {
            await getContentfulSeoData();
        });
        const seoFaqData = computed(() => { var _a; return (_a = seoContent.value[0]) === null || _a === void 0 ? void 0 : _a.fields; });
        const { getProductPriceBySku } = useProductPrices();
        const getBaseSearchQuery = () => ({
            filter: {
                sku: {
                    eq: routeData.sku,
                },
            },
            // configurations: Object.entries(route.value.query)
            //   .filter((config) => config[0] !== 'wishlist')
            //   .map(
            //     (config) => config[1],
            //   ) as string[],
        });
        const computedLoading = computed(() => productLoading.value || loading.value);
        const fetchProductBaseData = async (searchQuery = getBaseSearchQuery()) => {
            var _a;
            const result = await getProductDetails({
                ...searchQuery,
            });
            product.value = merge({}, product.value, (_a = result.items[0]) !== null && _a !== void 0 ? _a : null);
        };
        const fetchProductExtendedData = async () => {
            var _a, _b, _c, _d;
            productFetchingLoading.value = true;
            const { data } = await getProductPriceBySku(routeData.sku);
            // @ts-ignore
            product.value = merge({}, product.value, (_b = (_a = data.products) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b[0]);
            // eslint-disable-next-line no-underscore-dangle
            renderer.value = (_d = (_c = product.value) === null || _c === void 0 ? void 0 : _c.__typename) !== null && _d !== void 0 ? _d : ProductTypeEnum.SIMPLE_PRODUCT;
            productFetchingLoading.value = false;
        };
        const initialFetchData = async () => {
            var _a, _b;
            await fetchProductBaseData();
            if (Boolean((_a = product === null || product === void 0 ? void 0 : product.value) === null || _a === void 0 ? void 0 : _a.sku) === false)
                nuxtError({ statusCode: 404 });
            const tags = [
                {
                    prefix: CacheTagPrefix.View,
                    value: `product-${routeData.sku}`,
                },
            ];
            const productTags = [{
                    prefix: CacheTagPrefix.Product,
                    value: (_b = product === null || product === void 0 ? void 0 : product.value) === null || _b === void 0 ? void 0 : _b.uid,
                }];
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            addTags([...tags, ...productTags]);
        };
        const scrollTrack = () => {
            const header = document.querySelector('.header');
            if (header) {
                if (window.scrollY > 120) {
                    header.classList.remove('pointer-events-none');
                    header.classList.remove('opacity-0');
                    header.classList.add('opacity-100');
                }
                else {
                    header.classList.remove('opacity-100');
                    header.classList.add('opacity-0');
                    header.classList.add('pointer-events-none');
                }
            }
        };
        onMounted(async () => {
            // check if there is no cart and create one async to avoid blocking the page and optimize adding to cart
            if (!apiState.getCartId()) {
                loadCart({ realCart: true });
            }
            // moved initial fetch data in onmounted to prevent ssr wrong store fetchs. Cookies not always are updated when changing from one store to another
            await Promise.all([initialFetchData(), fetchProductExtendedData(), loadWishlist()]);
            nextTick(() => {
                document.addEventListener('scroll', scrollTrack);
            });
            // preload braintree client
            getClient();
        });
        onBeforeUnmount(() => {
            document.removeEventListener('scroll', scrollTrack);
        });
        const computedInstallVideoUrl = computed(() => { var _a; return INSTALL_VIREO_URL_MAP[(_a = product.value) === null || _a === void 0 ? void 0 : _a.sku]; });
        const hasEnoughReviews = computed(() => totalReviews.value >= MIN_REVIEWS_TO_SHOW);
        const emitSwitch = (sku) => {
            clearReviews();
            $eventChannel.emit('switchPdp', sku);
        };
        return {
            renderer,
            computedLoading,
            productFetchingLoading,
            computedInstallVideoUrl,
            loading,
            totalReviews,
            hasEnoughReviews,
            routeData,
            QUEST_GOOSE_BUNDLE_SKU,
            QUEST_3_BUNDLE_SKU,
            storeBaseUrl: $config.storeBaseUrl,
            product,
            seoFaqData,
            emitSwitch,
            fetchProduct: fetchProductExtendedData,
            getBaseSearchQuery,
            title: computed(() => { var _a, _b; return (_b = (_a = product.value) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : 'VR Prescription Lenses'; }),
            price: computed(() => { var _a, _b, _c, _d, _e; return (_e = (_d = (_c = (_b = (_a = product.value) === null || _a === void 0 ? void 0 : _a.price_range) === null || _b === void 0 ? void 0 : _b.minimum_price) === null || _c === void 0 ? void 0 : _c.final_price) === null || _d === void 0 ? void 0 : _d.value) !== null && _e !== void 0 ? _e : 0; }),
        };
    },
    head() {
        var _a, _b;
        // seo meta tags and og
        const { routeData } = usePageStore();
        const meta = SEO_META_TAGS_BY_PRODUCT[routeData.sku];
        // add current url to meta
        meta.meta.push({
            hid: 'og:url',
            property: 'og:url',
            content: `${this.storeBaseUrl}${this.$route.fullPath.slice(1)}`,
        });
        // faq seo meta tags
        const entities = ((_b = (_a = this.seoFaqData) === null || _a === void 0 ? void 0 : _a.items) !== null && _b !== void 0 ? _b : []).map((item) => ({
            '@type': 'Question',
            name: item.fields.question,
            acceptedAnswer: {
                '@type': 'Answer',
                text: item.fields.answer,
            },
        }));
        const jsonld = {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: entities,
        };
        if (entities.length > 0) {
            meta.script = [{
                    hid: 'jsonld-faq',
                    innerHTML: JSON.stringify(jsonld),
                    type: 'application/ld+json',
                }];
            // eslint-disable-next-line no-underscore-dangle
            meta.__dangerouslyDisableSanitizers = ['script'];
        }
        return meta;
    },
});
