import { SfLoader, SfNotification, SfSidebar, SfHeading, SfButton, SfProperty, SfPrice, SfCollectedProduct, SfQuantitySelector, SfBadge, SfImage, } from '@storefront-ui/vue';
import { defineComponent, useRouter, useContext, } from '@nuxtjs/composition-api';
import { useUiState, } from '~/composables';
import SvgImage from '~/components/General/SvgImage.vue';
import CouponCode from '~/components/CouponCode.vue';
import { useCartView } from '~/modules/checkout/composables/useCartView';
export default defineComponent({
    name: 'CartSidebar',
    components: {
        SfLoader,
        SfNotification,
        SfSidebar,
        SfButton,
        SfHeading,
        SfProperty,
        SfPrice,
        SfCollectedProduct,
        SfQuantitySelector,
        SfBadge,
        CouponCode,
        SvgImage,
        SfImage,
    },
    setup() {
        const cartView = useCartView();
        const { isCartSidebarOpen, toggleCartSidebar } = useUiState();
        const router = useRouter();
        const { app } = useContext();
        const goToCart = async () => {
            await router.push(app.localeRoute({ name: 'cart' }));
        };
        return {
            ...cartView,
            isCartSidebarOpen,
            toggleCartSidebar,
            goToCart,
        };
    },
});
