import { reactive, computed, ref } from '@nuxtjs/composition-api';
import { REVIEW_SKU_MAP, MIN_REVIEWS_TO_SHOW } from '../../constants.client';
const state = reactive({
    max: 5,
    reviews: new Map(),
    perPage: 6,
    page: 1,
    score: 0,
    totalReviews: 0,
    recommendPercent: '0',
});
export const useReviews = (sku) => {
    const reviews = computed(() => state.reviews);
    const max = computed(() => state.max);
    // const summary = computed(() => state.summary);
    const recommendPercent = computed(() => state.recommendPercent);
    const score = computed(() => state.score);
    const totalReviews = computed(() => state.totalReviews);
    const loading = ref(false);
    const clearReviews = () => {
        state.reviews = new Map();
        state.page = 1;
        state.totalReviews = 0;
        state.score = 0;
        state.recommendPercent = '0';
    };
    const getReviews = async () => {
        var _a, _b;
        const skuMap = REVIEW_SKU_MAP[sku];
        if (!skuMap)
            return;
        loading.value = true;
        const reviewsParam = {
            sku: skuMap,
            perPage: state.perPage,
            page: state.page,
        };
        const response = await fetch('/reviews', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(reviewsParam) });
        const reviewsResp = await response.json();
        loading.value = false;
        if (!reviewsResp.reviews)
            return;
        reviewsResp.reviews.forEach((review) => {
            state.reviews.set(review.id, review);
        });
        state.totalReviews = ((_a = reviewsResp === null || reviewsResp === void 0 ? void 0 : reviewsResp.total_rating) === null || _a === void 0 ? void 0 : _a.count) || 0;
        state.score = (reviewsResp === null || reviewsResp === void 0 ? void 0 : reviewsResp.total_rating.score) || 0;
        state.max = (reviewsResp === null || reviewsResp === void 0 ? void 0 : reviewsResp.total_rating.max) || 5;
        state.recommendPercent = ((((reviewsResp === null || reviewsResp === void 0 ? void 0 : reviewsResp.recommend_count) || 0) / (((_b = reviewsResp === null || reviewsResp === void 0 ? void 0 : reviewsResp.total_rating) === null || _b === void 0 ? void 0 : _b.count) || 1)) * 100).toFixed(0);
    };
    const getNextPage = async () => {
        if (state.reviews.size >= state.totalReviews)
            return;
        state.page += 1;
        await getReviews();
    };
    return {
        MIN_REVIEWS_TO_SHOW,
        reviews,
        score,
        max,
        loading,
        page: computed(() => state.page),
        totalReviews,
        getReviews,
        getNextPage,
        clearReviews,
        recommendPercent,
    };
};
