import { SfButton, SfLoader, SfProductCard, SfSection, } from '@storefront-ui/vue';
import { computed, defineComponent, onMounted, ref, } from '@nuxtjs/composition-api';
import { useImage, useProduct, } from '~/composables';
import useWishlist from '~/modules/wishlist/composables/useWishlist';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import { useUser } from '~/modules/customer/composables/useUser';
import { useAddToCart } from '~/helpers/cart/addToCart';
import { SortEnum } from '~/modules/GraphQL/types';
export default defineComponent({
    name: 'NewProducts',
    components: {
        SfProductCard,
        SfSection,
        SfLoader,
        SfButton,
    },
    props: {
        buttonText: {
            type: String,
            default: '',
        },
        link: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
    },
    setup() {
        const { isAuthenticated } = useUser();
        const { getProductList, loading, getProductPath, } = useProduct();
        const { isInWishlist, addOrRemoveItem } = useWishlist();
        const { addItemToCart, isInCart } = useAddToCart();
        const products = ref([]);
        const mappedProducts = computed(() => products.value.map((product) => ({
            ...product,
            isInWishlist: isInWishlist({ product }),
        })));
        const addItemToWishlist = async (product) => {
            await addOrRemoveItem({ product });
        };
        const { getMagentoImage, imageSizes } = useImage();
        onMounted(async () => {
            var _a;
            const newestProducts = await getProductList({
                pageSize: 4,
                currentPage: 1,
                sort: {
                    position: SortEnum.Asc,
                },
            });
            if ((_a = newestProducts === null || newestProducts === void 0 ? void 0 : newestProducts.items) === null || _a === void 0 ? void 0 : _a.length) {
                products.value = newestProducts.items;
            }
        });
        return {
            addItemToCart,
            addItemToWishlist,
            isAuthenticated,
            isInCart,
            isInWishlist,
            loading,
            mappedProducts,
            productGetters,
            getMagentoImage,
            imageSizes,
            getProductPath,
        };
    },
});
