import { SfButton, SfInput } from '@storefront-ui/vue';
import { computed, ref, defineComponent, } from '@nuxtjs/composition-api';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import { useCart } from '~/modules/checkout/composables/useCart';
export default defineComponent({
    name: 'CouponCode',
    components: {
        SfButton,
        SfInput,
    },
    setup() {
        const { cart, applyCoupon, removeCoupon, error, } = useCart();
        const couponCodeUserInput = ref('');
        const couponCodeAppliedToCart = computed(() => { var _a; return (_a = cartGetters.getAppliedCoupon(cart.value)) === null || _a === void 0 ? void 0 : _a.code; });
        const isCouponCodeApplied = computed(() => couponCodeAppliedToCart.value !== undefined);
        const hasAnyError = computed(() => Object.values(error.value).some((value) => value !== null));
        const applyCouponMsg = computed(() => error.value.applyCoupon || error.value.removeCoupon || { message: '', name: 'apply-coupon' });
        const applyOrRemoveCoupon = async () => {
            const operationPromise = isCouponCodeApplied.value
                ? removeCoupon({})
                : applyCoupon({ couponCode: couponCodeUserInput.value });
            await operationPromise;
            couponCodeUserInput.value = '';
        };
        return {
            couponCodeUserInput,
            couponCodeAppliedToCart,
            isCouponCodeApplied,
            applyCouponMsg,
            applyOrRemoveCoupon,
            hasAnyError,
        };
    },
});
